<template>
  <base-section id="features">
    <base-section-heading :title="$t('features.title')">
    </base-section-heading>
    <v-responsive class="mx-auto" max-width="1350">
      <v-container fluid>
        <v-row justify="center">
          <v-col
            v-for="card in cards"
            :key="card.titleCode"
            cols="12"
            sm="4"
            md="3"
          >
            <base-info-card justify="center" dark v-bind="card" />
          </v-col>
        </v-row>
      </v-container>
    </v-responsive>
  </base-section>
</template>

<script>
export default {
  name: "SectionFeatures",

  data: () => ({
    cards: [
      {
        icon: "mdi-cellphone-cog",
        title: "Setup and forget",
        titleCode: "features.setupTitle",
        textCode: "features.setupText",
        text: "You only have to set up your instnace once! For many use cases Tentifly will work in the background and keep you updated via SMS or E-mails.",
      },
      {
        icon: "mdi-account-heart-outline",
        title: "Customer experience",
        titleCode: "features.experienceTitle",
        textCode: "features.experienceText",
        text: "Your brand recognition, professional out-of-box design and versatile flows are some to name. Tentifly is designed around your customer and their retention!",
      },
      {
        icon: "mdi-rocket-launch-outline",
        title: "Efficienct and automated",
        titleCode: "features.automatedTitle",
        textCode: "features.automatedText",
        text: "Tentifly automates repetitive tasks and utilizes help of AI to increase your productivity and efficiency.",
      },
      {
        icon: "mdi-cash-multiple",
        title: "Reduced costs",
        titleCode: "features.costsTitle",
        textCode: "features.costsText",
        text: "Tentifly acts as your personal assistant and reduce your operating and administration costs by automating as much as possible or by making it 1-click.",
      },
      {
        icon: "mdi-brain",
        title: "Artificial intelligence",
        titleCode: "features.aiTitle",
        textCode: "features.aiText",
        text: "First in its class to utilize AI and provide functionality of your personal assistant on the fly. It keeps track on your business and lets you know of its needs.",
      },

      {
        icon: "mdi-account-group-outline",
        title: "Better collaboration",
        titleCode: "features.collaborationTitle",
        textCode: "features.collaborationText",
        text: "Tentifly aims to enhance collaboration via industry proven methods and by making work as predictable and planned as possible. Your workers know always what to do.",
      },

      {
        icon: "mdi-shield-check-outline",
        title: "Enhanced security",
        titleCode: "features.securityTitle",
        textCode: "features.securityText",
        text: "Access to your data is secure and granually configurable by you. Tentifly utilizes active measures while you are logged in and keeps your instance separate form everyone else!",
      },

      {
        icon: "mdi-data-matrix",
        title: "All digitalized",
        titleCode: "features.digitalizedTitle",
        textCode: "features.digitalizedText",
        text: "No need to ever keep records on paper or out of Tentifly. Everything is stored in your private TENTIFLY space.",
      },
      {
        icon: "mdi-shield-bug-outline",
        title: "Less errors",
        titleCode: "features.errorsTitle",
        textCode: "features.errorsText",
        text: "Automation and pre set record templates reduce human error and mistakes such as overselling or not selling enough, missing information, unauthorized access etc.",
      },
      {
        icon: "mdi-go-kart-track",
        title: "Data tracking",
        titleCode: "features.trackingTitle",
        textCode: "features.trackingText",
        text: "With us you have accurate data tracking and reporting. Tentifly keeps track of everything that happens to your stock, plans and orders.",
      },
      {
        icon: "mdi-arrow-decision-outline",
        title: "Better decision-making",
        titleCode: "features.decisionTitle",
        textCode: "features.decisionText",
        text: "Via simply generatable reports and with AI suggestions you will always make best decisions on the fly and long run.",
      },
      {
        icon: "mdi-account-network-outline",
        title: "Remote and autonomous",
        titleCode: "features.autonomousTitle",
        textCode: "features.autonomousText",
        text: "Tentifly is easy to access, works in every modern device and promotes autonomous and remote working.",
      },
    ],
  }),
};
</script>
